/* App.css - Enhanced version */

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  /* Enhanced Animated Background */
  background: linear-gradient(270deg, #FFDAB9, #FFE5B4, #FFDEAD);
  background-size: 600% 600%;
  animation: gradientAnimation 20s ease infinite;
  color: #333;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Enhanced Container Styles */
.container {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: containerFadeIn 0.5s ease-out;
  margin: 20px auto;
  max-width: 800px;
}

@keyframes containerFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.App {
  text-align: center;
  margin: 20px;
  animation: fadeIn 0.5s ease-out;
}

/* Enhanced Header Image */
.header-image {
  width: 250px;
  height: auto;
  margin: 0 auto 20px auto;
  display: block;
  border-radius: 50%;
  animation: floatAnimation 6s ease-in-out infinite;
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Enhanced Word Count Section */
.word-count {
  font-size: 1.2rem;
  animation: slideUp 0.5s ease-out;
}

.word-count p {
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.word-count .btn-link {
  color: #007bff;
  text-decoration: none !important;
  font-weight: bold;
  padding: 0;
  border: none;
  background: none;
  transition: all 0.3s ease;
  position: relative;
}

.word-count .btn-link:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #007bff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.word-count .btn-link:hover:after {
  transform: scaleX(1);
}

/* Enhanced Start Quiz Button */
.btn-start-quiz {
  background: linear-gradient(45deg, #FF7F50, #FF6347);
  border: none;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(255, 127, 80, 0.3);
  animation: pulseAnimation 2s infinite;
}

.btn-start-quiz:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 127, 80, 0.4);
  background: linear-gradient(45deg, #FF6347, #FF4500);
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Dark Mode Enhancements */
body.dark-mode {
  background: linear-gradient(270deg, #4B2C20, #2F1B0F, #59392D);
  background-size: 600% 600%;
  color: #f0f0f0;
}

body.dark-mode .container {
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

/* Add these new animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}